//dependencies imports
import { Checkbox, createStyles, Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';

//files/components imports
import { Checkboxes } from '../../../shared/index';
import { useFormStore, useStepsStore } from '../../../store';
import { ContainerForm } from '../index';
import { priceCatalog } from '../../../shared/Catalog';

const data = [
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/SeventhSection/picture.png'
				alt='Image/Video Galleries'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'Image/Video Galleries',
	},
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/SeventhSection/search.png'
				alt='Website Search'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'Website Search',
	},
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/SeventhSection/loyal-customer.png'
				alt='Mortgage Calculator'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'CRM Integration',
	},
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/SeventhSection/live-chat.png'
				alt='Live Chat/Chat Bot'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'Live Chat/Chat Bot',
		disable: true,
	},
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/SeventhSection/login.png'
				alt='User Login/Register'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'User Login/Register',
		disable: true,
	},
];

//StepWizard Props (Slider effect)
interface Props extends Partial<StepWizardChildProps> {
	nextStep?: () => void;
	previousStep?: () => void;
	goToStep?: (step: number) => void;
}

export const SeventhSectionForm = ({
	nextStep,
	previousStep,
	goToStep,
}: Props) => {
	const setAddons = useFormStore((state) => state.setData); //Function to set the design question of the form.
	const type = useFormStore((state) => state.form.type);
	const { PrevStepper, NextStepper } = useStepsStore();
	const business = useFormStore((state) => state.form.business);
	const form = useForm({
		initialValues: {
			addons: ['Forms/Lead Generation'],
		},
	});

	const { classes } = useStyle();

	const breakPoint = useMediaQuery('(max-width: 991px)');

	//Functions to control the stepper and wizard at the same time
	const handNextStep = () => {
		nextStep && nextStep();
		NextStepper();
	};

	const handPrevStep = () => {
		previousStep && previousStep();
		PrevStepper();
	};

	const landingGoToStep = () => {
		goToStep && goToStep(8);
		PrevStepper();
	};

	const websiteGoToStep = () => {
		goToStep && goToStep(9);
		PrevStepper();
	};

	return (
		<>
			<h2 className={classes.title}>Add-ons</h2>
			<form
				onSubmit={form.onSubmit((values) => {
					setAddons(values);
					handNextStep();
				})}
			>
				<ContainerForm
					handPrevStep={
						type === 'Landing Page'
							? landingGoToStep
							: business === 'Other'
							? websiteGoToStep
							: handPrevStep
					}
				>
					<Checkbox.Group
						orientation={breakPoint ? 'vertical' : 'horizontal'}
						className={classes.checkGroupContainer}
						{...form.getInputProps('addons', { type: 'checkbox' })}
						defaultValue={form.values.addons}
					>
						<Group className={classes.checkContainer}>
							<Checkboxes
								title={'Forms/Lead Generation'}
								image={
									<StaticImage
										placeholder='none'
										src='../../../images/SeventhSection/sign-up (1).png'
										alt='Forms/Lead Generation'
										quality={95}
										width={50}
										layout='fixed'
									/>
								}
								selected
								//@ts-ignore
								price={'Included'}
							/>
							{data.map((data, key) => {
								return (
									<Checkboxes
										title={data.title}
										image={data.image}
										key={key}
										//@ts-ignore
										price={priceCatalog.addons[data.title]}
										disable={type === 'Landing Page' ? data.disable : false}
										selected={type === 'Landing Page' ? data.disable : false}
									/>
								);
							})}
						</Group>
					</Checkbox.Group>
				</ContainerForm>
			</form>
		</>
	);
};

const useStyle = createStyles((theme) => ({
	title: {
		color: 'white',
		fontSize: 32,
		padding: 10,
		margin: '20px 0px',
		fontWeight: 400,
		lineHeight: 'normal',
		textAlign: 'center',

		[theme.fn.largerThan('md')]: {
			fontSize: 40,
		},

		[theme.fn.largerThan('xl')]: {
			fontSize: 65,
			lineHeight: '85px',
			margin: '40px 0px',
		},
	},
	checkGroupContainer: {
		width: '90%',
		margin: 'auto',
	},
	checkContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '20px auto',

		[theme.fn.largerThan('md')]: {
			flexDirection: 'row',
		},
	},
}));
