import { Checkbox, createStyles } from '@mantine/core';
import React, { ReactNode } from 'react';

interface Props {
	title: string;
	image: ReactNode;
	selected?: boolean;
	price: number | string;
	disable?: boolean;
}

export const Checkboxes = ({
	title,
	image,
	price,
	selected,
	disable,
}: Props) => {
	const { classes } = useStyle();

	return (
		<Checkbox
			aria-label='Checkbox'
			value={title}
			disabled={selected}
			indeterminate={disable}
			label={
				<>
					<div>
						<p>{title}</p>
						{price > 0 ? <p>Price: ${price}</p> : <p>Price: {price}</p>}
					</div>
					{image}
				</>
			}
			labelPosition='left'
			className={classes.checkbox}
			classNames={{
				body: classes.checkBody,
				labelWrapper: classes.checkLabelW,
				label: classes.checkLabel,
				input: classes.input,
			}}
		/>
	);
};

const useStyle = createStyles((theme) => ({
	input: {
		':disabled': {
			backgroundColor: '#228be6',
			borderColor: '#228be6',
			cursor: 'not-allowed',

			'+svg': {
				color: 'white !important',
			},
		},
	},

	checkbox: {
		width: '100%',
		borderRadius: 5,
		padding: 5,
		transition: 'all 300ms ease',
		border: `2px solid ${theme.colors.primary}`,
		alignSelf: 'stretch',

		'&:hover': {
			borderColor: '#fff',
		},

		'&:active': {
			borderColor: '#fff',
		},

		[theme.fn.largerThan('md')]: {
			width: '48%',
		},

		[theme.fn.largerThan('xl')]: {
			width: '32%',
		},
	},
	checkboxSelected: {
		width: '100%',
		borderRadius: 5,
		padding: 5,
		transition: 'all 300ms ease',
		border: `2px solid red`,
		alignSelf: 'stretch',

		'&:hover': {
			borderColor: '#fff',
		},

		'&:active': {
			borderColor: '#fff',
		},

		[theme.fn.largerThan('md')]: {
			width: '48%',
		},

		[theme.fn.largerThan('xl')]: {
			width: '32%',
		},
	},
	checkBody: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		transition: 'all 300ms ease',

		[theme.fn.largerThan('sm')]: {
			padding: '5px 10px',
		},
	},
	checkLabelW: {
		width: '100%',
	},
	checkLabel: {
		fontSize: 16,
		color: '#FFF !important',
		padding: 0,
		gap: 5,
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'flex-end',
		alignItems: 'center',

		[`@media(min-width: ${theme.breakpoints.sm}px)`]: {
			gap: 20,
		},

		[`@media(min-width: ${theme.breakpoints.md}px)`]: {
			fontSize: 20,
		},
	},
}));
