import { Box, createStyles, InputBase } from '@mantine/core';
import React from 'react';
import InputMask from 'react-input-mask';

interface PhoneInput {
	id?: string;
	name: string;
	placeholder: string;
	error?: string;
	form?: any;
}

const useInputTextStyle = createStyles((theme) => ({
	input: {
		color: theme.colors.black,
		border: '1px solid #ced4da',
		borderRadius: 4,
		paddingInline: 16,
		'&:focus': { border: '1px solid #228be6' },
	},
}));

export const PhoneInput = ({
	id,
	name,
	placeholder,
	error,
	form,
}: PhoneInput) => {
	const { classes } = useInputTextStyle();

	return (
		<Box>
			<InputBase
				id={id}
				aria-label={name}
				placeholder={placeholder}
				component={InputMask}
				mask='+1 (999) 999-9999'
				variant={'unstyled'}
				classNames={{ input: classes.input }}
				autoComplete='off'
				error={error && <>{error}</>}
				{...form.getInputProps(name, { withError: false })}
			/>
		</Box>
	);
};
