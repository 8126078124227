import { createStyles } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React, { useRef, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import * as yup from 'yup';
import { ContainerForm } from '../ContainerForm';
import { SliderInput } from '../../../shared/index';
import { useFormStore, useStepsStore } from '../../../store';

interface Props extends Partial<StepWizardChildProps> {
	nextStep?: () => void;
	previousStep?: () => void;
}

export const SecondSectionForm = ({ nextStep, previousStep }: Props) => {
	const { classes } = useStyles();
	const setPages = useFormStore((state) => state.setData);
	const { PrevStepper, NextStepper } = useStepsStore();
	const [sliderValue, setSliderValue] = useState(3);
	const ref = useRef<HTMLButtonElement>(null); //Uses the ref of a button input
	const handNextStep = () => {
		nextStep && nextStep();
		NextStepper();
	};

	const handPrevStep = () => {
		previousStep && previousStep();
		PrevStepper();
	};

	const form = useForm({
		initialValues: {
			pages: 3,
		},
		validate: yupResolver(
			yup
				.object()
				.shape({ pages: yup.number().required('You must pick a quantity') })
		),
	});

	return (
		<>
			<p className={classes.secondTitle}>How many pages do you want?</p>
			<form
				onSubmit={form.onSubmit((values) => {
					setPages({ pages: sliderValue });
					handNextStep();
				})}
			>
				<ContainerForm handPrevStep={handPrevStep} smallerContainer={true}>
					<div className={classes.sliderContainer}>
						<p className={classes.paragraph}>
							$1,000 per page, we recommend one page per service or keyword.
						</p>
						<SliderInput
							marks={sliderValues}
							value={sliderValue}
							onChange={(value) => {
								setSliderValue(value);
								setPages({ pages: value });
							}}
							max={50}
							min={3}
						/>
					</div>
					<button
						type='submit'
						ref={ref}
						className={classes.invisibleButton}
					></button>
				</ContainerForm>
			</form>
		</>
	);
};

const sliderValues = [
	{ value: 10, label: '10' },
	{ value: 20, label: '20' },
	{ value: 30, label: '30' },
	{ value: 40, label: '40' },
	{ value: 50, label: '+50' },
];

const useStyles = createStyles((theme) => ({
	paragraph: {
		color: '#fff',
		fontSize: 18,
		paddingBottom: 15,

		[theme.fn.largerThan('md')]: {
			fontSize: 20,
		},
	},
	sectionContainer: {
		paddingTop: '8%',
	},
	sliderContainer: {
		//	margin: '10% 4% 14% 4%',
		margin: '0 4%',
		marginBottom: '15%',
		width: '90%',
		marginTop: 50,

		[theme.fn.largerThan('md')]: {
			maxWidth: '992px',
			margin: '10% auto 10% auto',
		},
		[theme.fn.largerThan('xl')]: {
			margin: '8% auto 10% auto',
		},
	},
	itemContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',

		[theme.fn.largerThan('md')]: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-around',
		},
		[theme.fn.largerThan('lg')]: {
			width: '100%',
		},
	},
	secondTitle: {
		color: 'white',
		fontSize: 32,
		padding: 10,
		paddingBottom: 0,
		margin: '20px 0px opx',
		fontWeight: 400,
		lineHeight: 'normal',
		textAlign: 'center',

		[theme.fn.largerThan('md')]: {
			fontSize: 40,
		},

		[theme.fn.largerThan('xl')]: {
			fontSize: 65,
			lineHeight: '85px',
			margin: '40px 0px 0px',
		},
	},
	invisibleButton: {
		display: 'none',
	},
}));
