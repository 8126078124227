import { Button, Container, createStyles, Group } from '@mantine/core';
import React, { ReactNode, useMemo } from 'react';
import { projectCalculation } from '../../helpers';
import { useFormStore } from '../../store';

interface Props {
	handPrevStep?: () => void;
	smallerContainer?: boolean;
	children?: ReactNode;
	button?: boolean;
}

export const ContainerForm = ({
	handPrevStep,
	children,
	smallerContainer,
}: Props) => {
	const { classes } = useStyles();
	const formData = useFormStore((state) => state.form);

	const price = useMemo(() => projectCalculation(formData), [formData]);
	return (
		<Container fluid className={classes.formContainer}>
			{children}
			<Group
				position={handPrevStep ? 'apart' : 'right'}
				className={
					smallerContainer
						? classes.buttonsSmallContainer
						: classes.buttonsContainer
				}
			>
				{handPrevStep ? (
					<Button
						size='xl'
						onClick={handPrevStep}
						className={classes.leftButton}
					>
						Back
					</Button>
				) : (
					<></>
				)}
				<p className={classes.websiteCost}>
					Your website cost: ${price.toLocaleString()}
				</p>
				<Button size='xl' type='submit' className={classes.rightButton}>
					Next
				</Button>
			</Group>
		</Container>
	);
};

const useStyles = createStyles((theme) => ({
	formContainer: {
		width: '90%',
		maxWidth: 1650,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: 0,
		margin: '0px auto',
	},
	buttonsContainer: {
		width: '90%',
		padding: 5,
		margin: '15px auto',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	buttonsSmallContainer: {
		width: '90%',
		padding: 5,
		margin: '15px auto',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	leftButton: {
		order: 2,

		[theme.fn.largerThan('lg')]: {
			order: 1,
		},
	},
	rightButton: {
		order: 3,
	},
	websiteCost: {
		order: 1,
		width: '100%',
		color: '#fff',
		fontSize: 20,
		textAlign: 'center',
		borderRadius: 15,

		[theme.fn.largerThan('md')]: {
			fontSize: 23,
		},

		[theme.fn.largerThan('lg')]: {
			width: 'auto',
			order: 2,
		},
	},
}));
