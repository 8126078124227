import React, { ReactNode } from 'react';
import { MantineProvider, Container } from '@mantine/core';
import { MantineTheme } from '../../theme/mantine-theme';
import '../../styles/_index.css';
import { Header } from './Header';
import { Footer } from './Footer';

interface Props {
	children: ReactNode;
}
export const MainLayout = ({ children }: Props) => {
	return (
		<>
			<MantineProvider theme={MantineTheme} withGlobalStyles withNormalizeCSS>
				<div className='background-template'>
					<Header />
					<main>{children}</main>
					<Footer />
				</div>
			</MantineProvider>
		</>
	);
};
