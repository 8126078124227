import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface StepState {
	active: number;
	Business: boolean;
	setBusinesStep: (Business: boolean) => void;
	Type: string;
	setType: (Type: string) => void;
	NextStepper: () => void;
	PrevStepper: () => void;
	display: boolean;
	setDisplay: (display: boolean) => void;
}

export const useStepsStore = create<StepState>()(
	devtools(
		(set) => ({
			active: 0,
			Type: '',
			Business: false,
			setType: (Type) => set(() => ({ Type })),
			setBusinesStep: (Business) => set(() => ({ Business })),
			display: false,
			setDisplay: (display) => set(() => ({ display })),
			NextStepper: () =>
				set((state) => ({
					active:
						state.Type !== 'Website'
							? state.active + 20
							: state.Business == true
							? state.active + 6
							: state.active + (1 / 9) * 100, //Percentaje depends on Type of Website|Landing
				})),
			PrevStepper: () =>
				set((state) => ({
					active:
						state.Type !== 'Website'
							? state.active - 20
							: state.Business == true
							? state.active - 6
							: state.active - (1 / 9) * 100,
				})),
		}),
		{
			name: 'steps-storage',
		}
	)
);
