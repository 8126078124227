import * as React from 'react';
import type { HeadFC } from 'gatsby';
import { MainLayout } from '../components/layout/index';
import { CostCalculator, Forms } from '../components/index/index';

const IndexPage = () => {
	return (
		<>
			<MainLayout>
				<CostCalculator />
				<Forms />
			</MainLayout>
		</>
	);
};

export default IndexPage;

export const Head: HeadFC = () => (
	<>
		<title>Website Cost Calculator</title>
		<link
			href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap'
			rel='stylesheet preload prefetch'
			as='style'
			onLoad={function () {
				//@ts-ignore
				this.onLoad = null;
				//@ts-ignore
				this.rel = 'stylesheet';
			}}
			type='text/css'
			crossOrigin='anonymous'
		/>
		{/*  FONT AWESOME */}
		<link
			rel='stylesheet preload prefetch'
			href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css'
			as='style'
			type='text/css'
		></link>
		{/*<!-- HTML Meta Tags -->*/}
		<meta
			name='description'
			content='Know the price of your custom website with our cost calculator'
		/>

		{/*<!-- Facebook Meta Tags -->*/}
		<meta
			property='og:url'
			content='https://website-cost-calculator.orbitweb.net/'
		/>
		<meta property='og:type' content='website' />
		<meta property='og:title' content='Website Cost Calculator' />
		<meta
			property='og:description'
			content='Know the price of your custom website with our cost calculator'
		/>
		<meta
			property='og:image'
			content='https://firebasestorage.googleapis.com/v0/b/ycr-website.appspot.com/o/SEOImages%2Fwebsite-calculator-og.jpg?alt=media&token=d4d9715a-d0e3-457d-8d0a-6c57c0c00611'
		/>

		{/*<!-- Twitter Meta Tags -->*/}
		<meta name='twitter:card' content='summary_large_image' />
		<meta
			property='twitter:domain'
			content='website-calculator-demo.vercel.app'
		/>
		<meta
			property='twitter:url'
			content='https://website-cost-calculator.orbitweb.net/'
		/>
		<meta name='twitter:title' content='Website Cost Calculator' />
		<meta
			name='twitter:description'
			content='Know the price of your custom website with our cost calculator'
		/>
		<meta
			name='twitter:image'
			content='https://firebasestorage.googleapis.com/v0/b/ycr-website.appspot.com/o/SEOImages%2Fwebsite-calculator-og.jpg?alt=media&token=d4d9715a-d0e3-457d-8d0a-6c57c0c00611'
		/>
	</>
);
