import { Container, createStyles, Radio, Stack } from '@mantine/core';
import React, { ReactNode } from 'react';

interface Props {
	value: string;
	// descp: string;
	icon: ReactNode;
	price?: number;
	selected?: string | number;
	descp?: ReactNode;
	onClick?: any;
	quantity?: boolean;
	label?: string;
	small?: boolean;
}

export const RadioButtons = ({
	value,
	icon,
	price,
	selected,
	descp,
	onClick,
	quantity,
	label,
	small,
}: Props) => {
	const { classes } = useStyles();

	return (
		<Radio
			aria-label={descp + ' - Price: ' + price}
			onClick={onClick}
			className={
				quantity
					? small
						? classes.radioButtonSmall
						: classes.radioButton
					: classes.radioButtonSpecial
			}
			classNames={{
				labelWrapper:
					value === selected
						? classes.radioLabelVarSelected
						: classes.radioLabelVar,
				inner: classes.radioInner,
				radio: classes.radio,
				label: classes.label,
				body: classes.body,
			}}
			value={value}
			label={
				<Stack className={classes.stackContainer} aria-label='Options'>
					<Container className={classes.imageContainer}>{icon}</Container>
					<p>{descp}</p>
					<p className={classes.price}>
						{price === undefined ? label : `Price: $${price.toLocaleString()}`}
					</p>
				</Stack>
			}
		/>
	);
};

const useStyles = createStyles((theme) => ({
	body: {
		height: '100%',
	},
	stackContainer: {
		gap: theme.spacing.xs,
	},
	/**
	 * Styles for RadioButton
	 * Classname
	 */
	imageContainer: {
		marginTop: theme.spacing.md,
	},
	radioButton: {
		width: '100%',
		borderRadius: 15,

		[theme.fn.largerThan('sm')]: {
			width: '65%',
		},

		[theme.fn.largerThan('md')]: {
			width: '31%',
			alignSelf: 'stretch',
		},
	},
	radioButtonSmall: {
		width: '100%',
		borderRadius: 15,

		[theme.fn.largerThan('sm')]: {
			width: '65%',
		},

		[theme.fn.largerThan('md')]: {
			width: '20%',
			alignSelf: 'stretch',
		},
	},
	radioButtonSpecial: {
		width: '100%',
		borderRadius: 15,

		[theme.fn.largerThan('sm')]: {
			width: '65%',
		},

		[theme.fn.largerThan('md')]: {
			width: '48%',
			alignSelf: 'stretch',
		},

		[theme.fn.largerThan('xl')]: {
			width: '40%',
		},
	},
	label: {
		padding: 0,
		margin: 0,
		width: '100%',
		height: '100%',
		cursor: 'pointer',
		[theme.fn.largerThan('md')]: {
			fontSize: 23,
		},
	},
	price: {
		fontWeight: 700,
	},
	//Style for Radio Container
	radioLabelVar: {
		width: '100%',
		color: '#fff',
		fontSize: 20,
		//padding: '20px 10px 10px',
		textAlign: 'center',
		borderRadius: 15,
		transition: 'all 300ms ease',
		border: `2px solid ${theme.colors.primary}`,

		'&:hover': {
			borderColor: '#fff',
			cursor: 'pointer',
		},

		// [theme.fn.largerThan('md')]: {
		// 	fontSize: 23,
		// 	padding: '30px 10px 10px',
		// },
	},
	//Style for Radio Container When is selected
	radioLabelVarSelected: {
		width: '100%',
		color: '#fff',
		fontSize: 20,
		//padding: '20px 10px 10px',
		textAlign: 'center',
		borderRadius: 15,
		transition: 'all 300ms ease',
		border: '3px solid #fff',

		'&:hover': {
			borderColor: '#fff',
			cursor: 'pointer',
		},

		// [theme.fn.largerThan('md')]: {
		// 	fontSize: 23,
		// 	padding: '30px 10px 10px',
		// },
	},
	/**
	 * Style for Radio inner
	 * To not be dispalyed
	 */
	radioInner: {
		display: 'none',
		visibility: 'hidden',
	},
	radio: {
		display: 'none',
		visibility: 'hidden',
	},
}));
