import React from 'react';
import { Anchor } from '@mantine/core';
import { Link } from 'gatsby';

interface props {
	state: boolean;
}

export const MobileDropdownProducts = ({ state }: props) => {
	return (
		<>
			{/* LINKS */}
			<div
				className={
					state
						? 'containerMobileDropdown mobileDropActivatedProducts'
						: 'containerMobileDropdown'
				}
			>
				<Anchor
					rel='noopener noreferrer nofollow'
					href='https://orbitweb.net/landing-pages'
					className='mobileDropdown-item'
				>
					Landing Pages
				</Anchor>
			</div>
		</>
	);
};
