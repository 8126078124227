import React from 'react';
import { Container, Group, createStyles } from '@mantine/core';
//import { HiPhone } from 'react-icons/hi';
//import { FaFacebookF, FaTwitter, FaLinkedin } from 'react-icons/fa';
import NavBar from './navbar/navbar';

const useStyles = createStyles((theme) => ({
	headerContainer: {
		backgroundColor: 'transparent',
		color: 'white',
		fontSize: 16,
		padding: '8px 42px 8px 0px',

		[theme.fn.largerThan('md')]: {
			padding: '10px 58px 10px 0px',
		},
		[theme.fn.largerThan('lg')]: {
			padding: '12px 72px 12px 0px',
		},
	},
	iconLabel: {
		color: '#FFF',
	},
}));

export const Header = () => {
	const { classes } = useStyles();

	return (
		<>
			<NavBar />
			{/* <Group position='right'>
					<a
						href='tel:+18004306414'
						target='_blank'
						aria-label='Phone Number'
						className={classes.iconLabel}
						title='Contact us'
					>
						<HiPhone />
					</a>
					<a
						href='https://www.facebook.com/orbitweb.ca'
						target='_blank'
						aria-label='Facebook'
						className={classes.iconLabel}
						title='Facebook'
					>
						<FaFacebookF />
					</a>
					<a
						href='https://twitter.com/web_orbit'
						target='_blank'
						aria-label='Twitter'
						className={classes.iconLabel}
						title='Twitter'
					>
						<FaTwitter />
					</a>
					<a
						href='https://www.linkedin.com/company/orbitweb-digital-marketing/'
						target='_blank'
						aria-label='LinkedIn'
						className={classes.iconLabel}
						title='LinkedIn'
					>
						<FaLinkedin />
					</a>
				</Group> */}
		</>
	);
};
