import { createStyles, Group, keyframes, Radio, Collapse } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import * as yup from 'yup';
import { RadioButtons } from '../../../shared';
import { priceCatalog } from '../../../shared/Catalog';
import { SliderInput } from '../../../shared/SliderInput';
import { useFormStore, useStepsStore } from '../../../store';
import { ContainerForm } from '../ContainerForm';

const data = [
	{
		icon: (
			<StaticImage
				placeholder='none'
				src='../../../images/FourthSection/ecommerce.png'
				alt='ecommerce'
				layout='constrained'
				height={270}
				quality={95}
			/>
		),
		value: 'Yes',
		descp: 'Yes, I need eCommerce for my business',
		hanOnclick: true,
	},
	{
		icon: (
			<StaticImage
				placeholder='none'
				src='../../../images/FourthSection/low-sales.png'
				alt='dont need ecommerce'
				layout='constrained'
				height={270}
				quality={95}
			/>
		),
		value: 'No',
		descp: "No, I don't need eCommerce at this time",
		hanOnclick: false,
	},
];

//StepWizard Props (Slider effect)
interface Props extends Partial<StepWizardChildProps> {
	nextStep?: () => void;
	previousStep?: () => void;
}

export const FourSectionForm = ({ nextStep, previousStep }: Props) => {
	const [submitForm, setSubmitForm] = useState(false);

	const { classes } = useStyles(); //Const for styles
	/* A boolean value that is used to display the slider. */
	const display = useStepsStore((state) => state.display);
	const { PrevStepper, NextStepper } = useStepsStore();
	const setDisplay = useStepsStore((state) => state.setDisplay);
	const orientationQuery = useMediaQuery('(max-width: 991px)'); //Query to change the orientation of radio group.
	const ref = useRef<HTMLButtonElement>(null); //Uses the ref of a button input

	/** Function for set values to form  */
	const setEcommerce = useFormStore((state) => state.setData);
	const ecommerceAnswer = useFormStore((state) => state.form.ecommerce);
	const setShopify = useFormStore((state) => state.setData);
	const setPages = useFormStore((state) => state.setData);
	const [sliderValue, setSliderValue] = useState(100);

	//Functions to control the stepper and wizard at the same time
	const handNextStep = () => {
		nextStep && nextStep();
		NextStepper();
	};

	const handPrevStep = () => {
		previousStep && previousStep();
		PrevStepper();
	};

	//Form value inicialization
	const form = useForm({
		initialValues: {
			ecommerce: '',
			shopify: 'Shopify',
		},
		validate: yupResolver(
			yup.object().shape({
				ecommerce: yup.string().required('You must choose an option'),
				shopify: yup.string().required('You must choose an option 2'),
			})
		),
	});

	const marks = [
		{ value: 100, label: '100' },
		{ value: 500, label: '500' },
		{ value: 1000, label: '1000' },
		{ value: 1500, label: '1500' },
		{ value: 2000, label: '2000' },
		{ value: 2500, label: '2500' },
		{ value: 3000, label: '3000' },
		{ value: 3500, label: '3500' },
		{ value: 4000, label: '4000' },
		{ value: 4500, label: '4500' },
		{ value: 5000, label: '+5000' },
	];

	/* A useEffect that is triggered when the submitForm state changes. */
	useEffect(() => {
		if (display === false) {
			if (submitForm) {
				ref.current?.click();
				setSubmitForm(false);
			}
		}
	}, [form.values.ecommerce]); //Use effect triggers when form.values or submitForm value has changed

	return (
		<>
			<h2 className={classes.firstTitle}>Do you need eCommerce?</h2>
			<form
				onSubmit={form.onSubmit((values) => {
					if (display === true) {
						let ecommerceAnsawer = { ecommerce: values.ecommerce }; //Collect the sliderValue in an object variable.
						let eComQuantity = { eComQuantity: sliderValue };
						setEcommerce(ecommerceAnsawer); //We send the object variable done earlier to the store.ts
						setEcommerce(eComQuantity);
						let shopify = { shopify: values.shopify };
						setShopify(shopify);
						handNextStep();
					} else {
						setEcommerce({ ecommerce: values.ecommerce }); //We send the data as an object to the store.ts where it'll be managed.
						handNextStep();
					}
				})}
			>
				<ContainerForm handPrevStep={handPrevStep}>
					<Radio.Group
						orientation={orientationQuery ? 'vertical' : 'horizontal'}
						classNames={{ error: classes.error }}
						className={classes.radioGroupContainer}
						{...form.getInputProps('ecommerce')}
						onChange={(value) => {
							if (value === 'No') {
								setSubmitForm(true);
								form.setFieldValue('ecommerce', value);
								form.setFieldValue('shopify', 'No');
								setEcommerce({ ecommerce: 'No' });
								setShopify({ shopify: 'No' });
							} else {
								setPages({ ecommerce: 'Yes' });
								form.setFieldValue('ecommerce', value);
								form.setFieldValue('shopify', 'Shopify');
								setSliderValue(100);
							}
						}}
					>
						<Group className={classes.itemsContainer}>
							{data.map((radio, index) => (
								<RadioButtons
									value={radio.value}
									descp={radio.descp}
									icon={radio.icon}
									key={index}
									quantity={false}
									//@ts-ignore
									price={
										radio.value === 'Yes' ? priceCatalog.eCommerce.price : '0'
									}
									selected={ecommerceAnswer}
									onClick={() => {
										if (radio.hanOnclick) {
											setDisplay(true);
										} else {
											setDisplay(false);
										}
									}}
								/>
							))}
						</Group>
					</Radio.Group>
					<Collapse
						in={display}
						transitionDuration={1000}
						transitionTimingFunction='linear'
					>
						{display ? (
							<section className={classes.sectionAditional}>
								<div className={classes.containerShopify}>
									<p className={classes.paragraph}>What do you prefer?</p>
									<Radio.Group
										{...form.getInputProps('shopify')}
										classNames={{
											error: classes.error2,
											root: classes.radioRoot,
										}}
										className={classes.radioGroup}
										/** For solvent the error choise option */
										onChange={(value) => {
											form.setFieldValue('shopify', value);
										}}
									>
										<Radio
											classNames={{ label: classes.label }}
											value='Shopify'
											label='Shopify Backend'
										/>
										<Radio
											classNames={{ label: classes.label }}
											value='Custom'
											label='Custom Backend'
										/>
									</Radio.Group>
								</div>
								<div className={classes.sliderContainer}>
									<p className={classes.paragraph}>
										How many products will you be selling?
									</p>
									<p className={classes.littleparagraph}>
										* The first 100 products are included in the price
									</p>
									<SliderInput
										marks={marks}
										value={sliderValue}
										max={marks[marks.length - 1].value}
										min={100}
										step={100}
										onChange={(value) => {
											setSliderValue(value);
											setPages({ eComQuantity: value });
										}}
									/>
								</div>
							</section>
						) : (
							<></>
						)}
					</Collapse>
					<button
						type='submit'
						ref={ref}
						className={classes.invisibleButton}
					></button>
				</ContainerForm>
			</form>
		</>
	);
};

const useStyles = createStyles((theme) => ({
	radioGroupContainer: {
		width: '90%',
		margin: 'auto',
	},
	itemsContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '20px auto',

		[theme.fn.largerThan('md')]: {
			flexDirection: 'row',
		},
	},
	firstTitle: {
		color: 'white',
		fontSize: 32,
		padding: 10,
		margin: '20px 0px',
		fontWeight: 400,
		lineHeight: 'normal',
		textAlign: 'center',

		[theme.fn.largerThan('md')]: {
			fontSize: 40,
		},

		[theme.fn.largerThan('xl')]: {
			fontSize: 65,
			lineHeight: '85px',
			margin: '40px 0px',
		},
	},
	sliderContainer: {
		margin: '0 auto',
		width: '90%',
		marginTop: 25,
		// Para animar la caja
		[theme.fn.largerThan('md')]: {
			maxWidth: 1190,
			margin: '3% 7%',
		},
		[theme.fn.largerThan('xl')]: {
			margin: '3% 14%',
		},
	},
	invisibleButton: {
		display: 'none',
	},
	paragraph: {
		color: '#fff',
		fontSize: 18,

		[theme.fn.largerThan('md')]: {
			fontSize: 20,
		},
	},
	littleparagraph: {
		color: '#fff',
		fontSize: 14,
		[theme.fn.largerThan('md')]: {
			fontSize: 14,
		},
	},
	error: {
		color: '#FA5252',
		fontWeight: 600,
		fontSize: 18,
		position: 'relative',
		textAlign: 'center',
		[theme.fn.largerThan('md')]: {
			fontSize: 20,
		},
	},
	error2: {
		color: '#FA5252',
		fontWeight: 600,
		fontSize: 16,
		position: 'relative',
		left: 20,
		textAlign: 'center',
	},
	sectionAditional: {
		width: '100%',
		marginBottom: 50,
	},
	containerShopify: {
		width: '90%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		margin: '0 auto',
		gap: 15,
		marginTop: 25,
		[theme.fn.largerThan('md')]: {
			margin: '0 7%',
			flexDirection: 'row',
			gap: 30,
			justifyContent: 'flex-start',
		},
		[theme.fn.largerThan('xl')]: {
			margin: '0 14%',
		},
	},
	label: {
		color: '#ffffff',
		fontSize: 16,
	},
	radioRoot: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: 0,
		gap: '20',
	},
	radioGroup: {
		display: 'flex',
		alignItems: 'flex-start',
		paddingTop: 0,
		gap: '20',
		'> .mantine-Group-root': {
			padding: 0,
		},
		'> .mantine-Radio-label': {
			fontSize: 16,
		},
	},
}));
