import { createStyles, Progress, Stack, ThemeIcon, Text } from '@mantine/core';
import React from 'react';
import { useStepsStore } from '../../store';
import { BsCheckLg } from 'react-icons/bs';

export const CostCalculator = () => {
	const active = useStepsStore((state) => state.active);

	const { classes } = useStyles();

	return (
		<Stack className={classes.container}>
			<Stack className={classes.items}>
				<Stack className={classes.containerItems}>
					<ThemeIcon variant='filled' radius='xl' size='xl'>
						<BsCheckLg />
					</ThemeIcon>
					<Text sx={{ color: 'white' }}>Start</Text>
				</Stack>
				<Stack className={classes.progressBar} aria-label='Progress Bar'>
					<Progress value={active} aria-label='Progress Bar' />
				</Stack>
				<Stack className={classes.containerItems}>
					<ThemeIcon
						variant={active >= 100 ? 'filled' : 'default'}
						radius='xl'
						size='xl'
					>
						<BsCheckLg />
					</ThemeIcon>
					<Text sx={{ color: 'white' }}>Results</Text>
				</Stack>
			</Stack>
		</Stack>
	);
};

const useStyles = createStyles((theme) => ({
	container: {
		backgroundColor: 'transparent',
		marginInline: 20,
	},
	items: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
	containerItems: {
		alignItems: 'center',
		gap: 5,
		marginTop: 20,
		[theme.fn.smallerThan('xs')]: {
			display: 'none',
		},
	},
	progressBar: { width: '90%', justifyContent: 'center' },
}));
