//dependencies imports
import { Checkbox, createStyles, Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState, useRef } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
//files/components imports
import { Checkboxes } from '../../../shared/index';
import { useFormStore, useStepsStore } from '../../../store';
import { ContainerForm } from '../index';
import { priceCatalog } from '../../../shared/Catalog';

const RealState = [
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/SeventhSection/home.png'
				alt='Free Home Evaluation'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'Free Home Evaluation',
	},
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/SeventhSection/mortgage.png'
				alt='Mortgage Calculator'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'Mortgage Calculator',
	},
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/AddOnsBusiness/DoorOpen.png'
				alt='Request Showing'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'Request Showing',
	},
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/AddOnsBusiness/HouseList.png'
				alt='Mortgage Calculator'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'Home Search Engine',
	},
];

const school = [
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/AddOnsBusiness/learning.png'
				alt='Learning'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'Learning Management System',
	},
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/AddOnsBusiness/booking.png'
				alt='Booking'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'Booking System',
	},
];

const CarDealer = [
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/AddOnsBusiness/decode.png'
				alt='VIN decoder'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'VIN decoder',
	},
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/AddOnsBusiness/CarEvaluation.png'
				alt='Car evaluation'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'Free Car evaluation',
	},
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/AddOnsBusiness/PreAproved.png'
				alt='Mortgage Calculator'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'Get pre-approved form',
	},
	{
		image: (
			<StaticImage
				placeholder='none'
				src='../../../images/AddOnsBusiness/checklist.png'
				alt='Car Listing'
				quality={95}
				width={50}
				layout='fixed'
			/>
		),
		title: 'Car Search Engine',
	},
];

//StepWizard Props (Slider effect)
interface Props extends Partial<StepWizardChildProps> {
	nextStep?: () => void;
	previousStep?: () => void;
}

export const AddOnsBusiness = ({ nextStep, previousStep }: Props) => {
	const [submitForm, setSubmitForm] = useState(false);
	const { PrevStepper, NextStepper } = useStepsStore();
	const setAddons = useFormStore((state) => state.setData); //Function to set the design question of the form.
	const BusinessAnswer = useFormStore((state) => state.form.business);
	const ref = useRef<HTMLButtonElement>(null); //Uses the ref of a button input
	const [test, setTest] = useState(1);

	useEffect(() => {
		setTest((value) => value + 1);
	}, [BusinessAnswer]);

	const form = useForm({
		initialValues: {
			addOnsBusiness: [],
		},
	});

	const { classes } = useStyle();

	const breakPoint = useMediaQuery('(max-width: 991px)');

	//Functions to control the stepper and wizard at the same time
	const handNextStep = () => {
		nextStep && nextStep();
		NextStepper();
	};

	const handPrevStep = () => {
		previousStep && previousStep();
		PrevStepper();
	};

	useEffect(() => {
		if (submitForm) {
			ref.current?.click();
			setSubmitForm(false);
		}
	}, [form.values.addOnsBusiness]); //Use effect triggers when form.values or submitForm value has changed

	return (
		<>
			<h2 className={classes.title}>
				We offer specialized tools for {BusinessAnswer} industry:
			</h2>
			<form
				onSubmit={form.onSubmit((values) => {
					setAddons(values);
					form.reset();
					handNextStep();
				})}
			>
				<ContainerForm handPrevStep={handPrevStep}>
					<Checkbox.Group
						key={test}
						orientation={breakPoint ? 'vertical' : 'horizontal'}
						className={classes.checkGroupContainer}
						defaultValue={form.values.addOnsBusiness}
						{...form.getInputProps('addOnsBusiness', { type: 'checkbox' })}
					>
						<Group className={classes.checkContainer}>
							{BusinessAnswer === 'Real Estate' &&
								RealState.map((data, key) => {
									return (
										<Checkboxes
											title={data.title}
											image={data.image}
											key={key}
											//@ts-ignore
											price={priceCatalog.addOnsBusiness[data.title]}
										/>
									);
								})}
							{BusinessAnswer === 'Car Dealer' &&
								CarDealer.map((data, key) => {
									return (
										<Checkboxes
											title={data.title}
											image={data.image}
											key={key}
											//@ts-ignore
											price={priceCatalog.addOnsBusiness[data.title]}
										/>
									);
								})}
							{BusinessAnswer === 'School/Academy' &&
								school.map((data, key) => {
									return (
										<Checkboxes
											title={data.title}
											image={data.image}
											key={key}
											//@ts-ignore
											price={priceCatalog.addOnsBusiness[data.title]}
										/>
									);
								})}
						</Group>
					</Checkbox.Group>
				</ContainerForm>
			</form>
		</>
	);
};

const useStyle = createStyles((theme) => ({
	title: {
		color: 'white',
		fontSize: 32,
		padding: 10,
		margin: '20px 0px',
		fontWeight: 400,
		lineHeight: 'normal',
		textAlign: 'center',

		[theme.fn.largerThan('md')]: {
			fontSize: 40,
		},

		[theme.fn.largerThan('xl')]: {
			fontSize: 65,
			lineHeight: '85px',
			margin: '40px 0px',
		},
	},
	checkGroupContainer: {
		width: '90%',
		margin: 'auto',
	},
	checkContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '20px auto',

		[theme.fn.largerThan('md')]: {
			flexDirection: 'row',
		},
	},
}));
