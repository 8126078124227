import { priceCatalog } from '../shared';

export const projectCalculation = (formData: any) => {
	//Function that calculates the total cost of the project based on the choices and quantity.
	let finalPrice = 0;
	finalPrice += priceCatalog.design[formData.design];
	if (formData.type === 'Landing Page') finalPrice += 1000;
	if (formData.type === 'Website')
		finalPrice += formData.pages * priceCatalog.pages.price;
	if (formData.copywriting === 'Yes') {
		finalPrice += formData.pages * priceCatalog.copywriting.price;
	}
	if (formData.ecommerce === 'Yes') {
		finalPrice += priceCatalog.eCommerce.price;
		if (formData.eComQuantity > 100) {
			finalPrice +=
				(formData.eComQuantity - 100) * priceCatalog.eComQuantity.price;
		}
	}
	finalPrice += priceCatalog.logoDesign[formData.logoDesign];

	finalPrice += priceCatalog.cms[formData.cms];
	formData.addons?.forEach((addon) => {
		finalPrice += priceCatalog.addons[addon];
	});
	formData.addOnsBusiness?.forEach((addon) => {
		finalPrice += priceCatalog.addOnsBusiness[addon];
	});
	return finalPrice;
};
