import React from 'react';
import { useEffect, useRef } from 'react';
import Lottie from 'lottie-web';

interface Props {
	animationData?: any;
	className?: any;
	options?: any;
}

export const UseAnimation = ({
	animationData,
	className,
	options,
	...rest
}: Props) => {
	const container = useRef(null);

	useEffect(() => {
		Lottie.loadAnimation({
			container: container.current,
			renderer: 'svg',
			//loop: true,
			autoplay: true,
			animationData,
			...options,
		});
	}, [animationData, options]);

	return <div className={className} ref={container} {...rest}></div>;
};

export default UseAnimation;
