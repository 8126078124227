import React from 'react';
import { Link } from 'gatsby';

interface props {
	state: boolean;
}

export const MobileDropdownTools = ({ state }: props) => {
	return (
		<>
			{/* LINKS */}
			<div
				className={
					state
						? 'containerMobileDropdown mobileDropActivatedTools'
						: 'containerMobileDropdown'
				}
			>
				<Link
					rel='noopener noreferrer nofollow'
					to='/'
					className='mobileDropdown-item'
				>
					Website Cost Calculator
				</Link>
			</div>
		</>
	);
};
