import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { Link } from 'gatsby';
import MobileNavbar from './mobileNavbar';
//import ButtonScrollUp from './buttonScrollUp';
import UseAnimation from './useAnimation';
import './styles/main.scss';

const NavBar = () => {
	const [mobileMenuActive, setMobileMenuActive] = useState(false);
	const [isButtonVisible, setIsButtonVisible] = useState(false);

	const changeMobileMenu = () => {
		setMobileMenuActive(!mobileMenuActive);
	};

	const toggleFunction = () => {
		if (window.scrollY > 300) {
			setIsButtonVisible(true);
		} else {
			setIsButtonVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleFunction);

		return () => {
			window.removeEventListener('scroll', toggleFunction);
		};
	}, []);

	const options = useMemo(
		() => ({
			loop: false,
		}),
		[]
	);

	return (
		<>
			<div role='banner' className='navbarContainer'>
				<div className='navbarContainer_logoitems'>
					<div className='navbarContainer_logo'>
						<a aria-label='orbit-web-logo' href='https://orbitweb.net/'>
							<UseAnimation
								animationData={require('../../../images/navbar/Logo_OrbitWeb.json')}
								//@ts-ignore
								style={{ maxWidth: 342 }}
								options={options}
							/>
						</a>
					</div>
					<div className='navbarContainer_items'>
						<div
							className={`navbarContainer__item-link navbarContainer__item-submenu ${
								isButtonVisible ? 'menu-fixed' : ''
							}`}
							onClick={changeMobileMenu}
							onKeyDown={changeMobileMenu}
							role={'button'}
							aria-label='submenu button'
							tabIndex={0}
						>
							<i
								className='fa-solid fa-bars submenuButton'
								style={{ color: '#fff' }}
							></i>
						</div>
					</div>
				</div>
				<MobileNavbar
					mobileMenuActive={mobileMenuActive}
					changeMobileMenu={changeMobileMenu}
				/>
			</div>
			<div
				aria-hidden='true'
				className={`navbarOverlay  ${mobileMenuActive && 'overlayActive'}`}
				onClick={() => {
					setMobileMenuActive(false);
				}}
				// onKeyDown={() => {
				//   setMobileMenuActive(false)
				// }}
			></div>
			{/* <ButtonScrollUp /> */}
		</>
	);
};

export default NavBar;
