//dependencies imports
import { createStyles, Group, Radio } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import * as yup from 'yup';

//files/components imports
import { RadioButtons } from '../../../shared/index';
import { useFormStore, useStepsStore } from '../../../store';
import { ContainerForm } from '../index';
interface validations {
	icon: React.ReactNode;
	value: string;
	descp: string;
}

const data: validations[] = [
	{
		icon: (
			<StaticImage
				placeholder='none'
				src='../../../images/EightSection/RealState.png'
				alt='Real Estate'
				layout='constrained'
				height={270}
				quality={95}
			/>
		),
		value: 'Real Estate',
		descp: 'Real Estate',
	},
	{
		icon: (
			<StaticImage
				placeholder='none'
				src='../../../images/EightSection/CarDealer.png'
				alt='Car Dealer'
				layout='constrained'
				height={270}
				quality={95}
			/>
		),
		value: 'Car Dealer',
		descp: 'Car Dealer',
	},
	{
		icon: (
			<StaticImage
				placeholder='none'
				src='../../../images/EightSection/mortarboard.png'
				alt='Car Dealer'
				layout='constrained'
				height={270}
				quality={95}
			/>
		),
		value: 'School/Academy',
		descp: 'School or Academy',
	},
	{
		icon: (
			<StaticImage
				placeholder='none'
				src='../../../images/EightSection/Other.png'
				alt='Other Business'
				layout='constrained'
				height={270}
				quality={95}
			/>
		),
		value: 'Other',
		descp: 'Other',
	},
];

//StepWizard Props (Slider effect)
interface Props extends Partial<StepWizardChildProps> {
	nextStep?: () => void;
	previousStep?: () => void;
	goToStep?: (step: number) => void;
}

export const EightSectionForm = ({
	nextStep,
	previousStep,
	goToStep,
}: Props) => {
	const [submitForm, setSubmitForm] = useState(false);
	const BusinessAnswer = useFormStore((state) => state.form.business);
	const setBusiness = useFormStore((state) => state.setData);
	const { PrevStepper, NextStepper } = useStepsStore();
	const setBusinesStep = useStepsStore((state) => state.setBusinesStep);
	const ref = useRef<HTMLButtonElement>(null); //Uses the ref of a button input

	const handGoToStep = () => {
		goToStep && goToStep(11);
		NextStepper();
	};

	//Form value initialization
	const form = useForm({
		initialValues: {
			business: '',
		},
		validate: yupResolver(
			yup
				.object()
				.shape({ business: yup.string().required('You must choose an option') })
		),
	});

	const { classes } = useStyle();

	const breakPoint = useMediaQuery('(max-width: 991px)');

	//Functions to control the stepper and wizard at the same time
	const handNextStep = () => {
		nextStep && nextStep();
		NextStepper();
	};

	const handPrevStep = () => {
		goToStep && goToStep(7);
		PrevStepper();
	};

	useEffect(() => {
		if (submitForm) {
			ref.current?.click();
			setSubmitForm(false);
		}
	}, [form.values.business]); //Use effect triggers when form.values or submitForm value has changed

	return (
		<>
			<h2 className={classes.title}>
				Is this website for any specific industry?
			</h2>
			<form
				onSubmit={form.onSubmit((values) => {
					setBusiness({ business: values.business, addOnsBusiness: [] }); //We send the data as an object to the store.ts where it'll be managed.
					if (values.business === 'Other') {
						handGoToStep();
						setBusinesStep(false);
					} else {
						handNextStep();
						setBusinesStep(true);
					}
				})}
			>
				<ContainerForm handPrevStep={handPrevStep}>
					<Radio.Group
						orientation={breakPoint ? 'vertical' : 'horizontal'}
						className={classes.radioGroupContainer}
						classNames={{ error: classes.error }}
						{...form.getInputProps('business')}
						onChange={(value) => {
							setSubmitForm(true);
							form.setFieldValue('business', value);
						}}
					>
						<Group className={classes.radioContainer}>
							{data.map((data, key) => {
								return (
									<RadioButtons
										value={data.value}
										descp={data.descp}
										icon={data.icon}
										selected={BusinessAnswer}
										key={key}
										quantity={true}
										small
									/>
								);
							})}
						</Group>
					</Radio.Group>
					<button
						type='submit'
						ref={ref}
						className={classes.invisibleButton}
					></button>
				</ContainerForm>
			</form>
		</>
	);
};

const useStyle = createStyles((theme) => ({
	title: {
		color: 'white',
		fontSize: 32,
		padding: 10,
		margin: '20px 0px',
		fontWeight: 400,
		lineHeight: 'normal',
		textAlign: 'center',

		[theme.fn.largerThan('md')]: {
			fontSize: 40,
		},

		[theme.fn.largerThan('xl')]: {
			fontSize: 65,
			lineHeight: '85px',
			margin: '40px 0px',
		},
	},
	radioGroupContainer: {
		width: '90%',
		margin: 'auto',
	},
	radioContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '20px auto',

		[theme.fn.largerThan('md')]: {
			flexDirection: 'row',
		},
	},
	invisibleButton: {
		display: 'none',
	},
	error: {
		color: '#FA5252',
		fontWeight: 600,
		fontSize: 18,
		position: 'relative',
		textAlign: 'center',

		'&:before': {
			display: 'block',
			width: 20,
			height: 20,
			content: '" "',
			// backgroundImage: 'url("./icons/error.svg")',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'contain',
			position: 'absolute',
			left: -24,
		},

		[theme.fn.largerThan('md')]: {
			fontSize: 20,
		},
	},
}));
