import { createStyles, Grid } from '@mantine/core';
import React from 'react';
import StepWizard from 'react-step-wizard';

import { AddOnsBusiness } from './formSections/AddOnsBusiness';
import { EightSectionForm } from './formSections/EightSectionForm';
import {
	FifthSectionForm,
	FirstSectionForm,
	FourSectionForm,
	LandingPurpose,
	ResultSectionForm,
	SecondSectionForm,
	SeventhSectionForm,
	SixthSectionForm,
	ThirdSectionForm,
	Type,
} from './formSections/index';

export const Forms = () => {
	return (
		<Grid sx={{ minHeight: 'calc(100vh - 300px)' }} align='center' m={0}>
			<Grid.Col span={12}>
				<StepWizard>
					<Type />
					<SecondSectionForm />
					<FirstSectionForm />
					<ThirdSectionForm />
					<FourSectionForm />
					<FifthSectionForm />
					<SixthSectionForm />
					<LandingPurpose />
					<EightSectionForm />
					<AddOnsBusiness />
					<SeventhSectionForm />
					<ResultSectionForm />
				</StepWizard>
			</Grid.Col>
		</Grid>
	);
};
