import React, { useState } from 'react';
import { createStyles, Switch } from '@mantine/core';

interface validations {
	descp: string;
	value: string;
}

export const SwitchButton = ({ descp, value }: validations) => {
	const [checked, setChecked] = useState(false);

	const { classes } = useStyles();

	return (
		<Switch
			aria-label='Switch Button'
			checked={checked}
			onChange={(e) => setChecked(e.currentTarget.checked)}
			label={descp}
			labelPosition='left'
			value={value}
			color='#3d93ea'
			onLabel='Yes'
			offLabel='No'
			className={classes.switches}
			classNames={{
				labelWrapper: classes.labelWrapper,
				track: classes.track,
				body: classes.body,
			}}
		/>
	);
};

const useStyles = createStyles(() => ({
	switches: {
		padding: '10px 5px',
		width: '100%',
	},
	labelWrapper: {
		fontSize: 16,
		color: '#fff',
		fontWeight: 700,
		width: '100%',
	},
	track: {
		fontSize: 12,
		padding: 2,
	},
	body: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}));
