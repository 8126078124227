export const priceCatalog = {
	//Object with all the sections of the project and the respective prices.
	design: {
		'Simple Design': 0,
		'World Class Design': 3000,
		'': 0,
	},
	type: {
		'Landing Page': 1000,
		Website: 3000,
		'': 0,
	},
	pages: { price: 1000 },
	copywriting: { price: 350 },
	eCommerce: { price: 2500 },
	eComQuantity: { price: 1 },
	logoDesign: {
		'Logo and branding book': 5000,
		Logo: 2000,
		No: 0,
		'': 0,
	},
	cms: { Custom: 1500, WordPress: 2500, No: 0, '': 0 },
	business: { 'Real Estate': 0, 'Car Dealer': 0, Other: 0, '': 0 },
	addOnsBusiness: {
		'Free Home Evaluation': 500,
		'Mortgage Calculator': 500,
		'Request Showing': 250,
		'Home Search Engine': 3000,
		'VIN decoder': 500,
		'Free Car evaluation': 500,
		'Get pre-approved form': 250,
		'Car Search Engine': 3000,
		'Learning Management System': 3000,
		'Booking System': 250,
		'': 0,
	},
	addons: {
		'Forms/Lead Generation': 0,
		'Image/Video Galleries': 250,
		'Website Search': 500,
		'User Login/Register': 500,
		'Live Chat/Chat Bot': 1000,
		'CRM Integration': 500,
		'': 0,
	},
};
