import { Button, Center, createStyles, Modal, Stack } from '@mantine/core';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useMemo, useState } from 'react';
import { projectCalculation } from '../../../helpers';
import { UseAnimation } from '../../../shared/index';
import { useFormStore } from '../../../store';
import { GetCopy } from '../../Modal';
import '../../../styles/SendUsAMessage.scss';

export const ResultSectionForm = () => {
	const formData = useFormStore((state) => state.form);
	const { classes } = useStyles();
	const [opened, setOpened] = useState(false);

	const price = useMemo(() => projectCalculation(formData), [formData]);
	return (
		<>
			<Modal
				opened={opened}
				onClose={() => setOpened(false)}
				centered
				title='Contact details'
			>
				<GetCopy />
			</Modal>
			{/* <h2 className={classes.title}>You're done!</h2> */}
			<div className={classes.resultContainer}>
				<div className={classes.animationContainer}>
					<UseAnimation
						animationData={require('../../../images/cost-calculator/delight-developers.json')}
						className={classes.astroRocket}
					/>
				</div>
				<Stack spacing='xs' className={classes.dataResult}>
					<p className={classes.choicesText}>Your {formData.type} cost is:</p>
					<p className={classes.quantityText}>${price.toLocaleString()}</p>
					<ul className={classes.listContainer}>
						<li className={classes.item}>
							<StaticImage
								placeholder='none'
								alt='Verified'
								height={24}
								quality={95}
								src='../../../images/cost-calculator/verified.png'
								className={classes.check}
							/>
							<p className={classes.itemText}>
								Design level:{' '}
								<span className={classes.itemTextSpan}>{formData.design}</span>
							</p>
						</li>
						<li className={classes.item}>
							<StaticImage
								placeholder='none'
								alt='Verified'
								height={24}
								quality={95}
								src='../../../images/cost-calculator/verified.png'
								className={classes.check}
							/>
							<p className={classes.itemText}>
								Copywriting:{' '}
								<span className={classes.itemTextSpan}>
									{formData.copywriting}
								</span>
							</p>
						</li>
						{formData.type === 'Website' ? (
							<>
								<li className={classes.item}>
									<StaticImage
										placeholder='none'
										alt='Verified'
										height={24}
										quality={95}
										src='../../../images/cost-calculator/verified.png'
										className={classes.check}
									/>
									<p className={classes.itemText}>
										Number of pages:{' '}
										<span className={classes.itemTextSpan}>
											{formData.pages}
										</span>
									</p>
								</li>
								<li className={classes.item}>
									<StaticImage
										placeholder='none'
										alt='Verified'
										height={24}
										quality={95}
										src='../../../images/cost-calculator/verified.png'
										className={classes.check}
									/>
									<p className={classes.itemText}>
										eCommerce:{' '}
										<span className={classes.itemTextSpan}>
											{formData.ecommerce === 'Yes'
												? formData.ecommerce === 'Yes'
													? `Yes, Shopify with ${formData.eComQuantity} products`
													: ' Yes, Custom with ${formData.eComQuantity} products'
												: 'No'}
										</span>
									</p>
								</li>
								<li className={classes.item}>
									<StaticImage
										placeholder='none'
										alt='Verified'
										height={24}
										quality={95}
										src='../../../images/cost-calculator/verified.png'
										className={classes.check}
									/>
									<p className={classes.itemText}>
										Logo design:{' '}
										<span className={classes.itemTextSpan}>
											{formData.logoDesign}
										</span>
									</p>
								</li>
								<li className={classes.item}>
									<StaticImage
										placeholder='none'
										alt='Verified'
										height={24}
										quality={95}
										src='../../../images/cost-calculator/verified.png'
										className={classes.check}
									/>
									<p className={classes.itemText}>
										CMS/Blog:{' '}
										<span className={classes.itemTextSpan}>{formData.cms}</span>
									</p>
								</li>
								<li className={classes.item}>
									<StaticImage
										placeholder='none'
										alt='Verified'
										height={24}
										quality={95}
										src='../../../images/cost-calculator/verified.png'
										className={classes.check}
									/>
									<p className={classes.itemText}>
										Industry:{' '}
										<span className={classes.itemTextSpan}>
											{formData.business}
										</span>
									</p>
								</li>
							</>
						) : (
							<>
								<li className={classes.item}>
									<StaticImage
										placeholder='none'
										alt='Verified'
										height={24}
										quality={95}
										src='../../../images/cost-calculator/verified.png'
										className={classes.check}
									/>
									<p className={classes.itemText}>
										Purpose:{' '}
										<span className={classes.itemTextSpan}>
											{formData.purpose}
										</span>
									</p>
								</li>
							</>
						)}
						<li className={classes.item}>
							<div className={classes.itemText}>
								<StaticImage
									placeholder='none'
									alt='Verified'
									height={24}
									quality={95}
									src='../../../images/cost-calculator/verified.png'
									className={classes.check}
								/>
								Add-ons:{' '}
							</div>
							<Stack>
								{formData.addons?.map((value, key) => (
									<p key={key} className={classes.itemTextSpan}>
										{value}
									</p>
								))}
								{formData.addOnsBusiness?.map((value, key) => (
									<p key={key} className={classes.itemTextSpan}>
										{value}
									</p>
								))}
							</Stack>
						</li>
					</ul>
					<Center>
						<button
							className='marketing-angency__CTA__button'
							onClick={() => setOpened(true)}
						>
							<span>Send me this quote</span>
							<UseAnimation
								className='container'
								animationData={require('../../../images/cost-calculator/rocket-animation-white.json')}
							/>
						</button>

						{/* <Button
							onClick={() => setOpened(true)}
							color={'primary.0'}
							size='lg'
							radius={10}
							mb={10}
						>
							Send me this quote
						</Button> */}
					</Center>
				</Stack>
			</div>
		</>
	);
};

const useStyles = createStyles((theme) => ({
	title: {
		color: 'white',
		fontSize: 32,
		padding: 10,
		margin: '20px 0px',
		fontWeight: 400,
		lineHeight: 'normal',
		textAlign: 'center',

		[theme.fn.largerThan('md')]: {
			fontSize: 40,
		},

		[theme.fn.largerThan('xl')]: {
			fontSize: 65,
			lineHeight: '85px',
			margin: '40px 0px',
		},
	},
	resultContainer: {
		width: '90%',
		maxWidth: 1650,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: 5,
		margin: '30px auto',

		[theme.fn.largerThan('md')]: {
			flexDirection: 'row-reverse',
			justifyContent: 'space-around',
		},
	},
	animationContainer: {
		width: '100%',

		[theme.fn.largerThan('md')]: {
			width: '50%',
		},
	},
	astroRocket: {
		width: '100%',
		margin: '10px auto',

		[theme.fn.largerThan('md')]: {},
		[theme.fn.largerThan('lg')]: {},
	},
	dataResult: {
		width: '100%',
		backgroundColor: '#fff',
		borderRadius: 15,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		padding: 20,

		p: {
			margin: 0,
		},

		[theme.fn.largerThan('md')]: {
			width: '50%',
		},
	},
	choicesText: {
		color: 'rgba(0, 0, 0, 0.44);',
		fontSize: 20,
		fontWeight: 600,
		letterSpacing: '0.195rem',
		textAlign: 'center',

		[theme.fn.largerThan('md')]: {
			fontSize: 20,
			letterSpacing: '0.2rem',
		},
	},
	quantityText: {
		fontSize: 50,
		fontWeight: 700,
		textAlign: 'center',

		[theme.fn.largerThan('md')]: {
			fontSize: 68,
		},
	},
	listContainer: {
		width: '100%',
		padding: 0,
		margin: '0px auto',
		listStyle: 'none',

		[theme.fn.largerThan('xs')]: {
			width: '85%',
		},

		[theme.fn.largerThan('sm')]: {
			width: '70%',
		},

		[theme.fn.largerThan('md')]: {
			width: '100%',
		},

		[theme.fn.largerThan('xl')]: {
			width: '80%',
			margin: '20px auto',
		},
	},
	item: {
		display: 'flex',
		listStyle: 'none',
		alignItems: 'center',
		margin: '5px 0px',
		gap: 5,
		flexWrap: 'wrap',

		[theme.fn.largerThan('xs')]: {
			flexWrap: 'nowrap',
		},
	},
	check: {
		marginRight: 5,

		[theme.fn.largerThan('md')]: {
			marginRight: 10,
		},
	},
	itemText: {
		fontSize: 18,
		fontWeight: 600,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		alignSelf: 'flex-start',

		[theme.fn.largerThan('md')]: {
			fontSize: 20,
		},
	},
	selectedOptions: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		paddingLeft: 5,

		[theme.fn.largerThan('sm')]: {
			paddingLeft: 15,
		},
	},
	itemTextSpan: {
		fontSize: 18,
		fontWeight: 600,
		color: theme.colors.primary,
		paddingLeft: 30,

		[theme.fn.largerThan('xs')]: {
			fontSize: 20,
			paddingLeft: 10,
		},
	},
	modalOpener: {
		padding: 10,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	modalButton: {
		fontSize: 18,
		fontWeight: 700,
		backgroundColor: `${theme.colors.primary}`,
		transition: 'all 300ms ease',

		[theme.fn.largerThan('md')]: {
			fontSize: 20,
		},
	},
}));
