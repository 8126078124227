import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface formState {
	design?: string;
	type?: string;
	pages?: number;
	copywriting?: string;
	ecommerce?: string | number;
	eComQuantity?: number;
	logoDesign?: string;
	cms?: string;
	addons?: string[];
	shopify?: string;
	business?: string;
	addOnsBusiness?: string[];
	price?: number | string;
	fullName?: string;
	email?: string;
	purpose?: string;
	phoneNumber?: string;
	utm_adgroup?: string;
	utm_campaign?: string;
	utm_medium?: string;
	utm_source?: string;
	utm_term?: string;
}

interface vrState {
	form: formState;
	setData: (form: formState) => void;
}

export const useFormStore = create<vrState>()(
	devtools(
		(set) => ({
			form: {
				design: '',
				pages: 1,
				type: '',
				copywriting: '',
				ecommerce: 'No',
				logoDesign: '',
				cms: '',
				addons: ['Forms/Lead Generation'],
				shopify: '',
				business: '',
				addOnsBusiness: [''],
				price: 0,
				eComQuantity: 0,
				fullName: '',
				email: '',
				phoneNumber: '',
				purpose: '',
				utm_adgroup: 'XXXXXXX',
				utm_campaign: 'XXXXXXX',
				utm_medium: 'XXXXXXX',
				utm_source: 'XXXXXXX',
				utm_term: 'XXXXXXX',
			},
			setData: (form) =>
				set((state) => ({
					/* Merging the new form data with the existing form data. */
					form: { ...state.form, ...form },
				})),
		}),
		{
			name: 'form-storage',
		}
	)
);
