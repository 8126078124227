import React from 'react';
import { createStyles, Slider } from '@mantine/core';

interface Props {
	marks: any;
	value?: number;
	max?: number;
	min?: number;
	step?: number;
	scale?: (value: number) => void;
	onChange?: (value: number) => void;
	onChangeEnd?: (value: number) => void;
}

export const SliderInput = ({
	marks,
	value,
	max,
	min,
	step,
	scale,
	onChange,
	onChangeEnd,
}: Props) => {
	const { classes } = useStyles();

	return (
		<Slider
			aria-label='Slider Input'
			size='xl'
			marks={marks}
			classNames={{
				root: classes.root,
				track: classes.track,
				bar: classes.bar,
				mark: classes.mark,
				markLabel: classes.markLabel,
			}}
			value={value}
			onChange={onChange}
			onChangeEnd={onChangeEnd}
			min={min}
			step={step}
			max={max}
		/>
	);
};

const useStyles = createStyles((theme) => ({
	root: {
		//height: 20,

		[theme.fn.largerThan('md')]: {
			height: 25,
		},
	},
	track: {
		height: 20,

		[theme.fn.largerThan('md')]: {
			height: 25,
		},
	},
	bar: {},
	mark: {
		marginTop: 2,

		[theme.fn.largerThan('md')]: {
			marginTop: 4,
			height: 14,
			width: 14,
		},
	},
	markLabel: {
		color: '#FFF',
		fontSize: 22,
		marginTop: 5,

		[theme.fn.largerThan('md')]: {
			fontSize: 25,
			marginTop: 8,
		},
	},
}));
