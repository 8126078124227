import React from 'react';
import { Anchor } from '@mantine/core';
interface props {
	state: boolean;
}

export const MobileDropdown = ({ state }: props) => {
	return (
		<>
			{/* LINKS */}
			<div
				className={
					state
						? 'containerMobileDropdown mobileDropActivated'
						: 'containerMobileDropdown'
				}
			>
				{/* STYLE APPLIED ACCORDING TO THE STATE VALUE RECEIVED */}

				<Anchor
					rel='noopener noreferrer nofollow'
					href='https://orbitweb.net/ppc-agency/'
					className='mobileDropdown-item'
				>
					PPC Management
				</Anchor>

				<Anchor
					rel='noopener noreferrer nofollow'
					href='https://orbitweb.net/web-development/'
					className='mobileDropdown-item'
				>
					Web Development
				</Anchor>

				<Anchor
					rel='noopener noreferrer nofollow'
					href='https://orbitweb.net/ecommerce/'
					className='mobileDropdown-item'
				>
					eCommerce
				</Anchor>

				<Anchor
					rel='noopener noreferrer nofollow'
					href='https://orbitweb.net/seo/'
					className='mobileDropdown-item'
				>
					SEO
				</Anchor>
			</div>
		</>
	);
};
