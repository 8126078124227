import { Button, Group, Stack, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import 'yup-phone';
import { projectCalculation } from '../../helpers';
import { PhoneInput } from '../../shared/PhoneInput';
import { useFormStore } from '../../store';
import { ClientRequest } from '../../request/PostCalculator';

const schema = Yup.object().shape({
	fullName: Yup.string().required('The field is required.'),
	companyName: Yup.string().required('The field is required.'),
	phoneNumber: Yup.string()
		.required('The field is required.')
		.phone('CA', undefined, 'Phone number is invalid'),
	email: Yup.string()
		.trim()
		.required('The field is required.')
		.email('The e-mail address entered is invalid.'),
});

export const GetCopy = () => {
	const [send, setSend] = useState(false);
	const formData = useFormStore((state) => state.form);
	const form = useForm({
		initialValues: {
			fullName: '',
			email: '',
			phoneNumber: '',
			companyName: '',
			price: projectCalculation(formData).toLocaleString(),
		},
		validate: yupResolver(schema),
	});

	return (
		<form
			noValidate
			onSubmit={form.onSubmit((values) => {
				setSend(true);
				ClientRequest({ ...formData, ...values, email: values.email.trim() })
					.then(
						() => (window.location.href = 'https://orbitweb.net/thank-you/')
					)
					.catch(() => setSend(false));
			})}
		>
			<Stack>
				<TextInput
					data-autofocus
					required
					placeholder='Your name'
					{...form.getInputProps('fullName')}
				/>
				<TextInput
					required
					placeholder='your@email.com'
					{...form.getInputProps('email')}
				/>
				<TextInput
					required
					placeholder='Company name'
					{...form.getInputProps('companyName')}
				/>
				<PhoneInput
					name='phoneNumber'
					placeholder='+1 (506) 234-5678'
					error={form.errors.phoneNumber ? form.errors.phoneNumber + '' : ''}
					form={form}
				/>
			</Stack>
			<Group my={'sm'} position='apart' grow>
				<Button type='submit' loading={send}>
					Send
				</Button>
			</Group>
		</form>
	);
};
