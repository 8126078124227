import {MantineThemeOverride} from '@mantine/core';

export const MantineTheme: MantineThemeOverride = {
	fontFamily: 'Montserrat, san-serif',
	colors: {
		primary: ['#2EA2F0'],
		white: ['#FFFFFF'],
		dark: ['#000','#292929', '#1f1f1f'],
	},
}
