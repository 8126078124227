import React from 'react';
import { MobileDropdown } from './mobiledropdown';
import { useState } from 'react';
import { Anchor } from '@mantine/core';
import { MobileDropdownProducts } from './mobiledropdownProducts';
import { MobileDropdownTools } from './mobiledropdownTools';

interface props {
	mobileMenuActive: any;
	changeMobileMenu: any;
}

const MobileNavbar = ({ mobileMenuActive, changeMobileMenu }: props) => {
	const [subMenuServicios, setSubMenuServicios] = useState(false);
	const [subMenuProducts, setSubMenuProducts] = useState(false);
	const [subMenuTools, setSubMenuTools] = useState(false);
	// FUNCTION TO SHOW MOBILE SUB MENU SERVICES
	const handleClickServicios = () => {
		setSubMenuServicios(!subMenuServicios);
	};
	// FUNCTION TO SHOW MOBILE SUB MENU PRODUCTS
	const handleClickProducts = () => {
		setSubMenuProducts(!subMenuProducts);
	};

	// FUNCTION TO SHOW MOBILE SUB MENU Tools
	const handleClickTools = () => {
		setSubMenuTools(!subMenuTools);
	};

	return (
		<>
			<div
				className={
					mobileMenuActive
						? 'mobileNavContainer activeMobileMenu'
						: 'mobileNavContainer'
				}
			>
				{/* STYLE APPLIED ACCORDING TO mobileMenuActive VALUE RECEIVED */}

				{/* ICON TO CLOSE*/}
				<div className='mobileNavContainer__closeBtn'>
					<i
						role={'button'}
						aria-label='Close button'
						className='fa-solid fa-xmark navBarMobileBtnClose'
						onClick={changeMobileMenu}
						onKeyDown={changeMobileMenu}
						tabIndex={0}
					></i>
				</div>

				{/* MOBILE MENU LINKS CONTAINER*/}
				<div role='navigation' className='mobileNavContainer__items'>
					{/* INICIO */}
					<Anchor
						rel='noopener noreferrer nofollow'
						href='https://orbitweb.net/'
						className='mobileNavContainer__items-link'
					>
						Home
					</Anchor>
					<Anchor
						rel='noopener noreferrer nofollow'
						href='https://orbitweb.net/about/'
						className='mobileNavContainer__items-link'
					>
						About
					</Anchor>
					<Anchor
						rel='noopener noreferrer nofollow'
						href='https://orbitweb.net/blog/'
						className='mobileNavContainer__items-link'
					>
						Blog
					</Anchor>
					<Anchor
						rel='noopener noreferrer nofollow'
						href='https://orbitweb.net/support/'
						className='mobileNavContainer__items-link'
					>
						Support
					</Anchor>

					{/* SERVICIOS */}
					<div
						role={'button'}
						aria-label='Services button'
						tabIndex={0}
						onClick={handleClickServicios}
						onKeyDown={handleClickServicios}
						style={{ fontFamily: 'Montserrat' }}
						className={
							subMenuServicios
								? 'mobileNavContainer__items-link mobileServiciosLink mobileServiciosLinkActive'
								: 'mobileNavContainer__items-link mobileServiciosLink'
						}
					>
						<span>Services</span>
						<i
							className={
								subMenuServicios
									? 'fa-solid fa-chevron-down mobileDropIconActive'
									: 'fa-solid fa-chevron-down mobileDropIcon'
							}
						></i>
					</div>
					<MobileDropdown state={subMenuServicios} />
					{/**DROPDOWN */}
					<div
						role={'button'}
						aria-label='Services button'
						tabIndex={0}
						onClick={handleClickProducts}
						onKeyDown={handleClickProducts}
						style={{ fontFamily: 'Montserrat' }}
						className={
							subMenuProducts
								? 'mobileNavContainer__items-link mobileServiciosLink mobileServiciosLinkActive'
								: 'mobileNavContainer__items-link mobileServiciosLink'
						}
					>
						<span>Products</span>
						<i
							className={
								subMenuProducts
									? 'fa-solid fa-chevron-down mobileDropIconActive'
									: 'fa-solid fa-chevron-down mobileDropIcon'
							}
						></i>
					</div>
					<MobileDropdownProducts state={subMenuProducts} />

					<div
						role={'button'}
						aria-label='Tools button'
						tabIndex={0}
						onClick={handleClickTools}
						onKeyDown={handleClickTools}
						style={{ fontFamily: 'Montserrat' }}
						className={
							subMenuTools
								? 'mobileNavContainer__items-link mobileToolsLink mobileToolsLinkActive'
								: 'mobileNavContainer__items-link mobileToolsLink'
						}
					>
						<span>Tools</span>
						<i
							className={
								subMenuTools
									? 'fa-solid fa-chevron-down mobileDropIconActive'
									: 'fa-solid fa-chevron-down mobileDropIcon'
							}
						></i>
					</div>
					<MobileDropdownTools state={subMenuTools} />
				</div>
			</div>
		</>
	);
};

export default MobileNavbar;
