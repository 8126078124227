import { createStyles, Group, Radio } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import * as yup from 'yup';
import { RadioButtons } from '../../../shared';
import { useFormStore, useStepsStore } from '../../../store';
import { ContainerForm } from '../ContainerForm';
import { priceCatalog } from '../../../shared/Catalog';

const data = [
	{
		icon: (
			<StaticImage
				placeholder='none'
				src='../../../images/third-section/product.png'
				alt='copywriting'
				layout='constrained'
				height={270}
				quality={95}
			/>
		),
		value: 'Products',
		descp: 'I want to promote my products',
	},
	{
		icon: (
			<StaticImage
				placeholder='none'
				src='../../../images/third-section/service.png'
				alt='dont need copywriting'
				layout='constrained'
				height={270}
				quality={95}
			/>
		),
		value: 'Services',
		descp: 'I want to promote my services',
	},
];

//StepWizard Props (Slider effect)
interface Props extends Partial<StepWizardChildProps> {
	nextStep?: () => void;
	previousStep?: () => void;
	goToStep?: (step: number) => void;
}

export const LandingPurpose = ({ nextStep, previousStep, goToStep }: Props) => {
	const [submitForm, setSubmitForm] = useState(false);

	const { classes } = useStyles();
	//Query to change the orientation of radio group.
	const orientationQuery = useMediaQuery('(max-width: 991px)');
	const { PrevStepper, NextStepper } = useStepsStore();
	//Uses the ref of a button input
	const ref = useRef<HTMLButtonElement>(null);
	//Function to set the design question of the form.
	const setCopywriting = useFormStore((state) => state.setData);
	const purposeAwnswer = useFormStore((state) => state.form.purpose);

	const handPrevStep = () => {
		goToStep && goToStep(4);
		PrevStepper();
	};

	const handGoToStep = () => {
		goToStep && goToStep(11);
		NextStepper();
	};

	//Form value inicialization
	const form = useForm({
		initialValues: {
			purpose: '',
		},
		validate: yupResolver(
			yup.object().shape({
				purpose: yup.string().required('You must choose an option'),
			})
		),
	});

	/* A useEffect that is triggered when the submitForm state changes. */
	useEffect(() => {
		if (submitForm) {
			ref.current?.click();
			setSubmitForm(false);
		}
	}, [form.values.purpose]); //Use effect triggers when form.values or submitForm value has changed

	return (
		<>
			<h2 className={classes.firstTitle}>
				What is the purpose of this Landing Page?
			</h2>
			<form
				onSubmit={form.onSubmit((values) => {
					setCopywriting(values); //We send the data as an object to the store.ts where it'll be managed.
					handGoToStep();
				})}
			>
				<ContainerForm handPrevStep={handPrevStep}>
					<Radio.Group
						orientation={orientationQuery ? 'vertical' : 'horizontal'}
						classNames={{ error: classes.error }}
						className={classes.radioGroupContainer}
						{...form.getInputProps('purpose')}
						onChange={(value) => {
							setSubmitForm(true);
							form.setFieldValue('purpose', value);
						}}
					>
						<Group className={classes.itemsContainer}>
							{data.map((radio, index) => (
								<RadioButtons
									value={radio.value}
									descp={radio.descp}
									icon={radio.icon}
									selected={purposeAwnswer}
									key={index}
									price={undefined}
									quantity={false}
								/>
							))}
						</Group>
					</Radio.Group>
					<button
						type='submit'
						ref={ref}
						className={classes.invisibleButton}
					></button>
				</ContainerForm>
			</form>
		</>
	);
};

//Styles for this section
const useStyles = createStyles((theme) => ({
	radioGroupContainer: {
		width: '90%',
		margin: 'auto',
	},
	itemsContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '20px auto',

		[theme.fn.largerThan('md')]: {
			flexDirection: 'row',
		},
	},
	firstTitle: {
		color: 'white',
		fontSize: 32,
		padding: 10,
		margin: '20px 0px',
		fontWeight: 400,
		lineHeight: 'normal',
		textAlign: 'center',

		[theme.fn.largerThan('md')]: {
			fontSize: 40,
		},

		[theme.fn.largerThan('xl')]: {
			fontSize: 65,
			lineHeight: '85px',
			margin: '40px 0px',
		},
	},
	invisibleButton: {
		display: 'none',
	},
	error: {
		color: '#FA5252',
		fontWeight: 600,
		fontSize: 18,
		position: 'relative',
		textAlign: 'center',

		'&:before': {
			display: 'block',
			width: 20,
			height: 20,
			content: '" "',
			// backgroundImage: 'url("./icons/error.svg")',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'contain',
			position: 'absolute',
			left: -24,
		},

		[theme.fn.largerThan('md')]: {
			fontSize: 20,
		},
	},
}));
