import { Anchor, Container, createStyles, Group, Text } from '@mantine/core';
import React from 'react';

export const Footer = () => {
	const { classes, theme } = useStyles();

	return (
		<Container p={0} fluid>
			<Group className={classes.title}>
				<Anchor href='https://orbitweb.net/' className={classes.link}>
					Home{' '}
				</Anchor>
				<Anchor
					href='https://orbitweb.net/privacy-policy/'
					className={classes.link}
				>
					Privacy Policy{' '}
				</Anchor>
				<Anchor
					href='https://orbitweb.net/terms-of-service/'
					className={classes.terms}
				>
					Terms of service
				</Anchor>
				<Anchor href='https://orbitweb.net/support/' className={classes.link}>
					Support
				</Anchor>
			</Group>
			<Group className={classes.copyright}>
				<Text>2023 ©OrbitWeb</Text>
			</Group>
		</Container>
	);
};

const useStyles = createStyles((theme) => ({
	title: {
		backgroundColor: theme.colors.dark[1],
		color: theme.colors.white[0],
		display: 'flex',
		justifyContent: 'space-around',

		width: '100%',
	},
	link: {
		/* position: 'relative', */
		color: '#bbb',
		margin: '10px 5px',
		textDecoration: 'none',
		fontWeight: 700,
		'--clippi': 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
		'::after': {
			/* position: 'absolute', */
			width: '100%',
			display: 'block',
			content: "''",
			height: 2,
			background: '#bbb',
			bottom: 0,
			left: 0,
			clipPath: 'var(--clippi)',
			transition: 'clip-path .3s',
		},
		':hover': {
			'--clippi': 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
		},
	},
	terms: {
		color: '#bbb',
		margin: '10px 5px',
		textDecoration: 'none',
		fontWeight: 700,
		'--clippi': 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
		'::after': {
			/* position: 'absolute', */
			width: '100%',
			display: 'block',
			content: "''",
			height: 2,
			background: '#bbb',
			bottom: 0,
			left: 0,
			clipPath: 'var(--clippi)',
			transition: 'clip-path .3s',
		},
		':hover': {
			'--clippi': 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
		},
	},
	root: {
		padding: 0,
	},
	copyright: {
		backgroundColor: theme.colors.dark[2],
		color: 'hsla(0,0%,73%,.7)',
		display: 'flex',
		alignItems: 'center',
		height: 55,
		justifyContent: 'center',
		fontWeight: 700,
		[theme.fn.largerThan('md')]: {
			paddingLeft: 300,
			justifyContent: 'start',
		},
	},
}));
